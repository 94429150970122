<template lang="">
  <div class="mt-5">
    <div class="d-flex align-center justify-space-between mb-3">
      <div>
        <botao-padrao
          v-if="podeExcluir"
          class="my-2"
          outlined
          color="danger"
          :disabled="apenasVisualizar"
          @click="excluir"
        >
          <v-icon>$mdiTrashCanOutline</v-icon>
          {{ $t('geral.botoes.excluir') }}
        </botao-padrao>
      </div>
      <div class="d-flex">
        <botao-padrao
          v-if="buscarPermissao('Instrumento', 'Inserir')"
          :disabled="apenasVisualizar"
          @click="abreModalAdicionaInstrumento"
        >
          {{
            $t('modulos.ordem_servico.formulario.instrumento.novo_instrumento')
          }}
        </botao-padrao>
        <botao-padrao
          class="ml-2"
          :disabled="!form.solicitanteParticipanteId || apenasVisualizar"
          @click="associarDesassociarInstrumento"
        >
          {{
            $t(
              'modulos.ordem_servico.formulario.instrumento.selecionar_instrumentos'
            )
          }}
        </botao-padrao>
      </div>
    </div>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      sem-paginacao
      linha-hover
      :mostrar-seletor="false"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao
            text
            color="secondary"
          >
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item
              :disabled="!slotProps.data.analiseTecnicaId"
              @click="visualizarAnaliseTecnica(slotProps.data)"
            >
              {{
                $t(
                  'modulos.ordem_servico.formulario.instrumento.visualizar_analise_tecnica'
                )
              }}
            </dropdown-padrao-item>
          </dropdown-padrao>

          <div class="d-flex justify-center">
            <icone-padrao
              :icone="getSituacaoIcon(slotProps.data.situacao)"
              :tooltip="getSituacaoMessage(slotProps.data.situacao)"
            />
          </div>
        </div>
      </template>
      <template v-slot:observacao="{slotProps}">
        <div>
          <input-text
            v-model="slotProps.data.observacao"
            :disabled="apenasVisualizar"
            :label="$t('componentes.input_instrumento.tabela.observacao')"
            sem-label
            flat
            solo
            class="col-12"
            @input="alterarObservacao"
          />
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <instrumento-modal
      ref="modal-instrumento"
      :value="tabela.dados"
      :participante-id="form.solicitanteParticipanteId?.value"
      @associarInstrumentos="associarInstrumentos"
    />
    <VisualizarAnaliseTecnicaModal
      ref="modal-visualizar-analise-tecnica"
      :codigo-instrumento="codigoInstrumento"
      :nome-tipo-instrumento="nomeTipoInstrumento"
      :codigo-ordem-servico="codigoOrdemServico"
      :instrumento-valor="instrumentoValor"
    />
    <criacao-instrumento-modal ref="modal-criacao-instrumento" />
  </div>
</template>
<script>
import InstrumentoModal from './modais/InstrumentoModal';
import VisualizarAnaliseTecnicaModal from './modais/VisualizarAnaliseTecnicaModal';
import CriacaoInstrumentoModal from './modais/CriacaoInstrumentoModal.vue';
import { cloneDeep } from 'lodash';
import { enumOperacaoSituacaoIcons } from '@enums/icons';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    InstrumentoModal,
    VisualizarAnaliseTecnicaModal,
    CriacaoInstrumentoModal,
  },
  props: {
    form: {},
    apenasVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      codigoInstrumento: '',
      nomeTipoInstrumento: '',
      codigoOrdemServico: '',
      instrumentoValor: null,
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.codigo'
            ),
            width: 200,
            sortable: true,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.tipo_instrumento'
            ),
            sortable: true,
          },
          {
            value: 'numeroSerie',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.numero_serie'
            ),
            sortable: true,
          },
          {
            value: 'quantidadeInstrumentoFaixas',
            text: this.$t(
              'modulos.ordem_servico.formulario.instrumento.qtd_faixas'
            ),
            sortable: true,
          },
          {
            value: 'observacao',
            text: this.$t('componentes.input_instrumento.tabela.observacao'),
            sortable: false,
          },
        ],
        selecionados: [],
      },
    };
  },
  computed: {
    podeExcluir() {
      return this.tabela.selecionados != 0;
    },
  },
  watch: {
    'form.ordensServicosInstrumentos'() {
      this.tabela.dados = this.form.ordensServicosInstrumentos;
    },
  },
  mounted() {
    this.tabela.dados = this.form.ordensServicosInstrumentos;
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    visualizarAnaliseTecnica(item) {
      this.codigoInstrumento = item.codigoTag;
      this.nomeTipoInstrumento = item.tipoInstrumento.nome;
      this.codigoOrdemServico = this.form.codigo;
      this.instrumentoValor = item.instrumentoValor;
      this.$refs['modal-visualizar-analise-tecnica'].abrirModal(
        item.analiseTecnicaId
      );
    },
    associarDesassociarInstrumento() {
      this.$refs['modal-instrumento'].abrirModal(this.form);
    },
    abreModalAdicionaInstrumento() {
      this.$refs['modal-criacao-instrumento'].abrirModal();
    },
    associarInstrumentos(instrumentos) {
      const ordensServicosInstrumentosClonado = cloneDeep(
        this.form.ordensServicosInstrumentos
      );
      this.form.ordensServicosInstrumentos = [];

      ordensServicosInstrumentosClonado.forEach((item) => {
        if (item.flagJaExistente && item.situacao !== 'SemMovimento') {
          this.form.ordensServicosInstrumentos.push(item);
        }
      });

      instrumentos.forEach((instrumento) => {
        const instrumentoJaAdicionado =
          this.form.ordensServicosInstrumentos.find(
            (item) => item.instrumentoId == instrumento.id
          );
        if (!instrumentoJaAdicionado) {
          this.form.ordensServicosInstrumentos.push({
            ...instrumento,
            instrumentoId: instrumento.id
          });
        }
      });
    },
    excluir: function () {
      this.confirmarExcluir().then(() => {
        this.tabela.selecionados.forEach((element) => {
          this.form.ordensServicosInstrumentos.splice(
            this.form.ordensServicosInstrumentos.indexOf(element),
            1
          );
        });
        this.tabela.selecionados = [];
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;
        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
    alterarObservacao(obs){
      this.form.ordensServicosInstrumentos.observacao = obs
    }
  },
};
</script>

<template lang="">
  <modal-padrao
    ref="modal-criacao-instrumento"
    max-width="90%"
    :titulo="$t('modulos.ordem_servico.formulario.instrumento.novo_instrumento')"
    :titulo-ok="$t('geral.botoes.salvar_f2')"
    :mostrar-botoes="false"
  >
    <formulario
      :mostra-titulo="false"
      :formulario-modal="true"
      :titulo-modal-salvar="$t('geral.botoes.salvar_f2')"
      @fecha-modal-criacao-intrumento="fecharModal"
    />
  </modal-padrao>
</template>
<script>
import formulario from '@views/modulos/cadastros/instrumento/formulario';
export default {
  components: { formulario },
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  methods: {
    abrirModal(){
      this.$refs['modal-criacao-instrumento'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-criacao-instrumento'].fecharModal();
    }
  },
};
</script>

<template lang="">
  <modal-padrao
    ref="modal-criacao-cliente"
    max-width="90%"
    :titulo="$t('modulos.ordem_servico.formulario.instrumento.novo_cliente')"
    :titulo-ok="$t('geral.botoes.salvar')"
    :mostrar-botoes="false"
  >
    <formulario
      :mostra-titulo="false"
      :formulario-modal="true"
      :cliente-id="clienteId"
      @fecha-modal-criacao-cliente="fecharModal"
    />
  </modal-padrao>
</template>
<script>
import formulario from '@views/modulos/cadastros/participantes/formulario';
export default {
  components: { formulario },
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      clienteId: null
    }
  },
  methods: {
    abrirModal(clienteId){
      this.clienteId = clienteId;
      this.$refs['modal-criacao-cliente'].abrirModal();
    },
    fecharModal() {
      this.$emit('atualiza-contato', this.clienteId)
      this.$refs['modal-criacao-cliente'].fecharModal();
    }
  },
};
</script>

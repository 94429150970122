import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import { DropdownModelDoisParametros } from '../geral/DropdownModelDoisParametros';

export class OrdemServicoModel {
  constructor({
    id,
    participanteContatoId,
    clienteParticipanteContatoId,
    participante,
    codigo,
    clienteParticipante,
    dataPrevisaoEntrega,
    dataRecebimento = new Date(),
    embalagemId,
    localExecucao = null,
    notaFiscal,
    dataEntrega,
    ordemServicoAnexo,
    ordensServicosInstrumentos = [],
    prioridadeId,
    situacao = 'AguardandoAnaliseTecnica',
    solicitanteParticipante,
    tipoEnvioId,
    envioMaterial
  }) {
    this.id = id;
    this.envioMaterial = envioMaterial;
    this.participanteId = participante
    if (participante) this.participanteId = new DropdownModel(participante);
    this.clienteParticipanteId = clienteParticipante;
    if (clienteParticipante)
      this.clienteParticipanteId = new DropdownModel(clienteParticipante);
    this.participanteContatoId = participanteContatoId;
    this.clienteParticipante = clienteParticipante;
    this.clienteParticipanteContatoId = clienteParticipanteContatoId;
    this.codigo = codigo;
    this.dataPrevisaoEntrega = dataPrevisaoEntrega;
    this.dataRecebimento = dataRecebimento;
    this.dataEntrega = dataEntrega;
    this.embalagemId = embalagemId;
    this.notaFiscal = notaFiscal;
    this.anexos = ordemServicoAnexo ?? [];
    this.anexos?.forEach((anexo) => (anexo.flagJaExistente = true));
    this.ordensServicosInstrumentos = ordensServicosInstrumentos?.map(instrumento => { return { ...instrumento, flagJaExistente: true } });
    this.prioridadeId = prioridadeId;
    this.situacao = situacao;
    this.tipoEnvioId = tipoEnvioId;
    this.solicitanteParticipanteId = solicitanteParticipante;
    if (solicitanteParticipante)
      this.solicitanteParticipanteId = new DropdownModel(
        solicitanteParticipante
      );
    this.localExecucaoId = localExecucao;
    if (localExecucao)
      this.localExecucaoId = new DropdownModelDoisParametros(
        localExecucao,
        'nome',
        'instalacaoCliente'
      );

    this.ordensServicosInstrumentos.forEach(el => {
      el.idBanco = el.id;
      el.id = el.instrumento.id;
    })
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.clienteParticipanteId = this.clienteParticipanteId?.value;
    retorno.localExecucaoId = this.localExecucaoId;
    if (this.localExecucaoId?.value)
      retorno.localExecucaoId = this.localExecucaoId?.value;
    retorno.solicitanteParticipanteId = this.solicitanteParticipanteId?.value;
    retorno.anexos?.forEach(
      (anexo) => (anexo.id = !anexo.flagJaExistente ? null : anexo.id)
    );
    if (!retorno.id) {
      retorno.ordemServicoAnexo = _.cloneDeep(retorno.anexos);
      retorno.anexos = null;
    }
    retorno.instrumentos = this.ordensServicosInstrumentos?.map((instrumento) => {
      return {
        id: instrumento.flagJaExistente ? instrumento.idBanco : null,
        instrumentoId: instrumento.instrumentoId,
        situacao: 'AguardandoAnaliseTecnica',
        observacao: instrumento.observacao
      }
    })

    retorno.situacao = 'AguardandoAnaliseTecnica'
    return retorno;
  }
}

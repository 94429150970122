var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-padrao',{ref:"modal-envio-material",attrs:{"max-width":"60%","titulo":_vm.$t(
    'modulos.ordem_servico.titulos.envios_materiais_pendentes'
  ),"titulo-ok":_vm.$t('geral.botoes.confirmar'),"titulo-cancelar":_vm.$t('geral.botoes.voltar')},on:{"ok":_vm.salvar}},[_c('tabela-padrao-prime-vue',{staticClass:"mt-2",attrs:{"dados":_vm.tabela.dados,"filtro-geral":"","colunas":_vm.tabela.colunas,"sem-paginacao":"","filtro-global-focus":"","filters":_vm.tabela.filters,"selecionar-apenas-um":"","ajustar-altura-linha":"","mostrar-acoes":false,"global-filters":[
      'codigo',
      'tipoEnvio.nome',
      'situacao',
      'dataAgendamento'
    ]},on:{"fechar-modal-click-linha":() => _vm.salvar()},model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="">
  <modal-padrao
    ref="modal-visualizar-analise-tecnica"
    max-width="90%"
    :titulo="
      $t(
        'modulos.ordem_servico.formulario.instrumento.visualizar_analise_tecnica'
      )
    "
    :titulo-ok="$t('geral.botoes.salvar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    :ok-desabilitado="true"
    :mostra-botao-salvar="false"
  >
    <v-form ref="form">
      <div class="row d-flex">
        <div class="titulo-pagina col-12 col-md-7" />
        <div class="titulo-pagina col-12 col-md-5">
          <div
            class="d-flex"
            style="place-content: space-between"
          >
            <div>{{ $t('modulos.operacao.formulario.valor_total') }}</div>
            <div style="color: #039855">
              {{ progressBarInformation() }}
            </div>
          </div>
          <v-progress-linear
            :value="percentualProgressBar()"
            height="20"
            background-color="#E4E7EC"
            color="#039855"
          />
        </div>
      </div>
      <div class="row">
        <input-text
          v-model="codigoInstrumento"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.instrumento')"
          disabled
        />
        <input-text
          v-model="nomeTipoInstrumento"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.tipo_instrumento')"
          disabled
        />
        <input-text
          v-model="codigoOrdemServico"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.ordem_servico')"
          disabled
        />
      </div>
      <div class="row">
        <input-text
          :value="form.flagSemConserto ? possivelConserto : 'Sim'"
          class="col-12 col-md-3"
          :label="
            $t(
              'modulos.operacao.formulario.possivel_executar_concerto_instrumento'
            )
          "
          disabled
        />
        <input-text
          v-if="form.flagSemConserto"
          :value="form.descricaoJustificativaSemConserto"
          class="col-12 col-md-9"
          :label="$t('modulos.operacao.formulario.descricaoJustificativaSemConserto')"
          disabled
        />
        <input-text
          v-model="form.localExecucaoNome"
          :class="`col-12 col-md-${form.flagSemConserto ? 4 : 3}`"
          :label="$t('modulos.operacao.formulario.local_execucao')"
          disabled
        />
        <input-text
          :value="opcoes.tipoPrazoEntrega?.find(el => el.value == form.tipoPrazoEntrega)?.text"
          :class="`col-12 col-md-${form.flagSemConserto ? 4 : 3}`"
          :label="$t('modulos.operacao.formulario.tipo_prazo_entrega')"
          disabled
        />
        <input-text
          v-model="form.prazoEntregaEmDias"
          :class="`col-12 col-md-${form.flagSemConserto ? 4 : 3}`"
          :label="$t('modulos.operacao.formulario.prazo_entrega')"
          type="number"
          disabled
        />
      </div>
      <div class="row">
        <input-text
          v-model="form.flagGarantia"
          class="col-12 col-md-3"
          :label="$t('modulos.operacao.formulario.garantia')"
          disabled
        />
        <input-text
          v-model="form.justificativaGarantia"
          class="col-12 col-md-9"
          :label="$t('modulos.operacao.formulario.justificativa_garantia')"
          disabled
        />
        <input-text
          :value="form.flagGarantia"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.aprovacao_garantia')"
          disabled
        />
        <input-text
          :value="form.usuarioAprovacao?.nome"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.usuario_aprovacao')"
          disabled
        />
        <input-text
          :value="form.usuarioAprovacao && form.dataAprovacaoGarantia"
          class="col-12 col-md-4"
          :label="$t('modulos.operacao.formulario.data_aprovacao')"
          disabled
        />
      </div>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.calibracao')"
      >
        <div
          v-if="form.calibracao?.id"
          class="row"
        >
          <input-text
            v-model="form.tipoCalibracaoNome"
            class="col-md-4"
            :label="$t('modulos.operacao.formulario.tipo_calibracao')"
            disabled
          />
          <input-text
            v-model="form.pontosCalibracaoInstrumento"
            :label="
              $t('modulos.operacao.formulario.pontos_calibracao_instrumento')
            "
            class="col-md-3"
            type="number"
            disabled
          />
          <input-text
            v-model="form.pontosExtras"
            :label="$t('modulos.operacao.formulario.pontos_extras')"
            class="col-md-3"
            type="number"
            disabled
          />
        </div>
        <tabela-padrao-prime-vue
          v-if="form.calibracao?.id"
          :dados="form.calibracaoDados"
          class="mt-2"
          :colunas="tabelaCalibracao.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="form.calibracaoDados?.length && form.calibracaoDados?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaCalibracao.porPagina"
          :pagina-atual="tabelaCalibracao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaCalibracao.colunas.map(c => c.value)"
        />
        <!-- <data-table
          v-if="form.calibracao?.id"
          :value="form.calibracaoDados"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm mt-2 tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column
            field="faixa"
            :header="$t('modulos.operacao.tabela.faixa')"
          />
          <Column
            field="tempoExecucaoPadrao"
            :header="$t('modulos.operacao.tabela.horas_execucao_padrao')"
          />
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.horas_execucao')"
          />
          <Column
            field="minimoPontosServico"
            :header="$t('modulos.operacao.tabela.minimo_pontos')"
          />
          <Column
            field="codigoContrato"
            :header="$t('modulos.operacao.tabela.codigo_contrato')"
          />
          <Column
            field="tipoAprovacao"
            :header="$t('modulos.operacao.tabela.tipo_aprovacao')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.tipo_aprovacao?.find(el => el.value == slotProps.data?.tipoAprovacao)?.text }}</span>
            </template>
          </Column>
          <Column
            field="aprovado"
            :header="$t('modulos.operacao.tabela.aprovado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps?.data?.aprovado)?.text }}</span>
            </template>
          </Column>
          <Column
            field="executado"
            :header="$t('modulos.operacao.tabela.executado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps.data.executado)?.text }}</span>
            </template>
          </Column>
        </data-table> -->
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.manutencao')"
      >
        <tabela-padrao-prime-vue
          v-if="form.manutencoes?.length"
          :dados="form.manutencoes"
          class="mt-2"
          :colunas="tabelaManutencao.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="!!form.manutencoes?.length && form.manutencoes?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaManutencao.porPagina"
          :pagina-atual="tabelaManutencao.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaManutencao.colunas.map(c => c.value)"
        />
        <!-- <data-table
          v-if="form.manutencoes?.length"
          :value="form.manutencoes"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column
            field="nome"
            :header="$t('modulos.operacao.tabela.nome')"
          />
          <Column
            field="faixa"
            :header="$t('modulos.operacao.tabela.faixa')"
          />
          <Column
            field="tempoExecucaoPadrao"
            :header="$t('modulos.operacao.tabela.horas_execucao_padrao')"
          />
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.horas_execucao')"
          />
          <Column
            field="contrato"
            :header="$t('modulos.operacao.tabela.codigo_contrato')"
          />
          <Column
            field="tipoAprovacao"
            :header="$t('modulos.operacao.tabela.tipo_aprovacao')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.tipo_aprovacao?.find(el => el.value == slotProps.data.tipoAprovacao)?.text }}</span>
            </template>
          </Column>
          <Column
            field="aprovado"
            :header="$t('modulos.operacao.tabela.aprovado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps.data.aprovado)?.text }}</span>
            </template>
          </Column>
          <Column
            field="executado"
            :header="$t('modulos.operacao.tabela.executado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps.data.executado)?.text }}</span>
            </template>
          </Column>
        </data-table> -->
      </accordion-padrao>
      <accordion-padrao
        class="col-12"
        cor-azul-texto
        alterar-cor
        :titulo="$t('modulos.operacao.steps.pecas')"
      >
        <tabela-padrao-prime-vue
          v-if="form.pecas?.length"
          :dados="form.pecas"
          class="mt-2"
          :colunas="tabelaPecas.colunas"
          sem-paginacao
          :mostrar-seletor="false"
          :mostra-header="false"
          :paginacao-em-memoria="!!form.pecas?.length && form.pecas?.length > 5"
          ajustar-altura-linha
          :mostrar-acoes="false"
          :por-pagina="tabelaPecas.porPagina"
          :pagina-atual="tabelaPecas.paginaAtual"
          filtro-geral
          :filters="filters"
          :global-filters="tabelaPecas.colunas.map(c => c.value)"
        />
        <!-- <data-table
          v-if="form.pecas?.length"
          :value="form.pecas"
          show-gridlines
          :paginator="false"
          class="p-datatable-sm tabela-prime-vue-inherit"
          :rows="50"
          :scrollable="true"
          scroll-height="500px"
          data-key="id"
          :row-hover="true"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[10, 25, 50]"
          current-page-report-template="Mostrando {first} a {last} de {totalRecords} itens"
          responsive-layout="scroll"
        >
          <Column
            field="codigo"
            :header="$t('modulos.operacao.tabela.codigo')"
          />
          <Column
            field="nome"
            :header="$t('modulos.operacao.tabela.nome')"
          />
          <Column
            field="fabricante"
            :header="$t('modulos.operacao.tabela.fabricante')"
          />
          <Column
            field="quantidadeEstoque"
            :header="$t('modulos.operacao.tabela.quantidade_estoque')"
          />
          <Column
            field="prazoEntrega"
            :header="$t('modulos.operacao.tabela.prazo_entrega')"
          />
          <Column
            field="tempoExecucao"
            :header="$t('modulos.operacao.tabela.horas_execucao')"
          />
          <Column
            field="quantidadeOrcada"
            :header="$t('modulos.operacao.tabela.quantidade_orcada')"
          />
          <Column
            field="tipoAprovacao"
            :header="$t('modulos.operacao.tabela.tipo_aprovacao')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.tipo_aprovacao?.find(el => el.value == slotProps.data.tipoAprovacao)?.text }}</span>
            </template>
          </Column>
          <Column
            field="aprovado"
            :header="$t('modulos.operacao.tabela.aprovado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps.data.aprovado)?.text }}</span>
            </template>
          </Column>
          <Column
            field="executado"
            :header="$t('modulos.operacao.tabela.executado')"
          >
            <template #body="slotProps">
              <span>{{ opcoes.booleano?.find(el => el.value == !!slotProps.data.executado)?.text }}</span>
            </template>
          </Column>
        </data-table> -->
      </accordion-padrao>
    </v-form>
  </modal-padrao>
</template>
<script>
import AnaliseTecnicaService from '@common/services/cadastros/AnaliseTecnicaService';
import OmieService from '@common/services/cadastros/OmieService';
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';

export default {
  props: {
    codigoInstrumento: { type: String, default: '' },
    nomeTipoInstrumento: { type: String, default: '' },
    codigoOrdemServico: { type: String, default: '' },
    instrumentoValor: { type: Number, default: 0 },
  },
  data() {
    return {
      form: {},
      input: false,
      valido: false,
      valorTotal: 0,
      codigoString: '',
      opcoes: {
        booleano: helpers.BoleanoEnum,
        possivelConserto: [
          {
            text: 'Sim',
            value: 6,
          },
          {
            text: 'Não, sem calibração',
            value: 'SemCalibracao',
          },
          {
            text: 'Não, sem conserto',
            value: 'SemConserto',
          },
          {
            text: 'Não compensa financeiramente',
            value: 'NaoCompensaFinanceiramente',
          },
          {
            text: 'Não, fora do escopo de calibração',
            value: 'ForaDoEscopoCalibracao',
          },
          {
            text: 'Não, fora do escopo de manutenção',
            value: 'ForaDoEscopoManutencao',
          },
        ],
        tipoPrazoEntrega: [
          {
            text: 'Contrato',
            value: 'Contrato',
          },
          {
            text: 'Requisitos do cliente',
            value: 'RequisitosCliente',
          },
          {
            text: 'Calculado por Setor',
            value: 'CalculadoSetor',
          },
          {
            text: 'Arbitrário',
            value: 'Arbitrario',
          },
        ],
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tabelaCalibracao: {
        colunas: [
        {
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa'),
        },
        {
          value: 'minimoPontosServico',
          text: this.$t('modulos.operacao.tabela.quantidade_pontos'),
        },
        {
          value: 'tempoExecucaoPadrao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'contrato.codigo',
          text: this.$t('modulos.operacao.tabela.codigo_contrato'),
        },
        {
          value: 'tipoAprovacao',
          text: this.$t('modulos.operacao.tabela.tipo_aprovacao'),
          formatter: v => helpers.TipoAprovacaoEnum.find(el => el.value == v)?.text ?? v
        },
        {
          value: 'aprovado',
          text: this.$t('modulos.operacao.tabela.aprovado'),
          formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
        },
        {
          value: 'executado',
          text: this.$t('modulos.operacao.tabela.executado'),
          formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
        }
      ],
        paginaAtual: 1,
        porPagina: 5,
      },
      tabelaManutencao: {
        colunas: [
        {
          value: 'codigo',
          text: this.$t('modulos.operacao.tabela.codigo_servico'),
        },
        {
          value: 'nome',
          text: this.$t('modulos.operacao.tabela.nome'),
        },
        {
          value: 'faixa',
          text: this.$t('modulos.operacao.tabela.faixa'),
        },
        {
          value: 'tempoExecucaoPadrao',
          text: this.$t('modulos.operacao.tabela.horas_execucao_padrao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'tempoExecucao',
          text: this.$t('modulos.operacao.tabela.horas_execucao'),
          formatter: v => v && helpers.formatarNumeroComPrecisao(v)
        },
        {
          value: 'ultimaExecucao',
          text: this.$t('modulos.operacao.tabela.ultima_execucao'),
          formatter: v => v ? helpers.formatarDataBr(v) : '',
        },
        {
          value: 'tipoAprovacao',
          text: this.$t('modulos.operacao.tabela.tipo_aprovacao'),
          formatter: v => helpers.TipoAprovacaoEnum.find(el => el.value == v)?.text ?? v
        },
        {
          value: 'aprovado',
          text: this.$t('modulos.operacao.tabela.aprovado'),
          formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
        },
        {
          value: 'executado',
          text: this.$t('modulos.operacao.tabela.executado'),
          formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
        }
      ],
        paginaAtual: 1,
        porPagina: 5,
      },
      tabelaPecas: {
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.operacao.tabela.peca'),
            sorteable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.operacao.tabela.nome'),
            sorteable: true,
          },
          {
            value: 'fabricante',
            text: this.$t('modulos.operacao.tabela.fabricante'),
            sorteable: true,
          },
          {
            value: 'quantidadeEstoque',
            text: this.$t('modulos.operacao.tabela.quantidade_estoque'),
            sorteable: true,
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.operacao.tabela.prazo_entrega'),
            sorteable: true,
            formatter: v => v || null
          },
          {
            value: 'tempoExecucao',
            text: this.$t('modulos.operacao.tabela.horas_execucao'),
            sorteable: true,
            formatter: v => v && helpers.formatarNumeroComPrecisao(v)
          },
          {
            value: 'quantidadeOrcada',
            text: this.$t('modulos.operacao.tabela.quantidade_orcada'),
            sorteable: true,
          },
          {
            value: 'ultimaExecucao',
            text: this.$t('modulos.operacao.tabela.ultima_execucao'),
            sorteable: true,
            formatter: v => v ? helpers.formatarDataBr(v) : '',
          },
          {
          value: 'tipoAprovacao',
          text: this.$t('modulos.operacao.tabela.tipo_aprovacao'),
          formatter: v => helpers.TipoAprovacaoEnum.find(el => el.value == v)?.text ?? v
          },
          {
            value: 'aprovado',
            text: this.$t('modulos.operacao.tabela.aprovado'),
            formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
          },
          {
            value: 'executado',
            text: this.$t('modulos.operacao.tabela.executado'),
            formatter: v => this.opcoes.booleano?.find(el => el.value == !!v)?.text
          }
        ],
        paginaAtual: 1,
        porPagina: 5,
      },
    };
  },
  computed:{
    possivelConserto(){
      return this.opcoes.possivelConserto?.find(el => el.value == this.form.justificativaSemConserto)?.text
    }
  },
  watch: {},
  methods: {
    abrirModal: function (analiseTecnicaId) {
      this.$refs['modal-visualizar-analise-tecnica'].abrirModal();
      this.buscaDadosAnaliseTecnica(analiseTecnicaId);
    },
    buscaDadosAnaliseTecnica(analiseTecnicaId) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      AnaliseTecnicaService.visualizar(analiseTecnicaId)
        .then((res) => {
          this.form = res.data;
          this.form.calibracaoDados = [];
          this.form.calibracaoDados.push(res.data.calibracao);
          this.form.localExecucaoNome = res.data.localExecucao?.nome;
          this.form.tipoCalibracaoNome =
            res.data.calibracao?.tipoCalibracao?.nome;

          this.form.pontosCalibracaoInstrumento =
            res.data.calibracao?.quantidadePontos;

          if (
            res.data.calibracao?.minimoPontosServico >
            this.form.pontosCalibracaoInstrumento
          ) {
            this.form.pontosExtras = 0;
          } else {
            this.form.pontosExtras =
              this.form.pontosCalibracaoInstrumento -
              res.data.calibracao?.minimoPontosServico;
          }

          if (res.data.flagGarantia) this.form.flagGarantia = 'Sim';
          else this.form.flagGarantia = 'Não';

          this.valorTotal = res.data.valorTotal ? res.data.valorTotal : 0;

          this.percentualProgressBar();
          this.progressBarInformation();

          this.codigoString = '';
          if (res.data.pecas.length) {
            res.data.pecas.map((item) => {
              this.codigoString += `&Codigos=${item.codigo}`;
            });
          }

          this.montaListagemPecas(this.codigoString);
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    montaListagemPecas(codigoString) {
      if (codigoString) {
        let parametros = {
          flagFiltrarCodigos: true,
        };
        this.$store.dispatch('Layout/iniciarCarregamento');
        OmieService.buscaDadosOmie(codigoString, parametros)
          .then((res) => {
            this.form.pecas.map((item) => {
              if (res.data.produtos.length) {
                res.data.produtos.map((itemProduto) => {
                  if (item.codigo === itemProduto.codigo)
                    item.quantidadeEstoque = itemProduto.saldo;
                });
              }
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    percentualProgressBar() {
      let percentualTotal = 0;
      if (this.valorTotal) {
        percentualTotal = Number(
          (this.valorTotal / this.instrumentoValor) * 100
        ).toFixed(3);
      } else this.percentualTotal = 0;

      return Math.trunc(percentualTotal);
    },
    progressBarInformation() {
      if (!this.instrumentoValor) {
        return 'Instrumento sem valor';
      }
      let percentualTotal = 0;
      if (this.valorTotal) {
        percentualTotal = Number(
          (this.valorTotal / this.instrumentoValor) * 100
        ).toFixed(3);
      } else this.percentualTotal = 0;

      let information = `${Math.trunc(percentualTotal)}%`;

      return information;
    },
  },
};
</script>
<style lang="scss" scoped>
.tabela-prime-vue-inherit {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
</style>

<template>
  <div>
    <v-form ref="form" class="row mt-2">
      <input-cliente
        v-if="buscarPermissao('Participante', 'Inserir')"
        v-model="form.clienteParticipanteId"
        class="col-12 col-md-4"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.cliente')
        "
        obrigatorio
        estilo-botao-adicionar
        com-botao-adicionar
        :disabled="!!form.id || apenasVisualizar || !!this.$route.query?.enviomaterial || !!form.envioMaterial"
      >
        <template #botaoAdicionar>
          <botao-padrao
            :botao-adicionar-input="true"
            :disabled="apenasVisualizar"
            @click="abreModalAdicionaCliente"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-cliente>
      <div class="col-12 col-md-4">
        <input-select
          v-if="buscarPermissao('Participante', 'Inserir')"
          v-model="form.clienteParticipanteContatoId"
          :options="opcoes.contato"
          :label="
            $t('modulos.ordem_servico.formulario.dados_ordem_servico.contato')
          "
          :placeholder="$t('geral.inputs.selecione')"
          :loading="loading.contatoLoading"
          :input-adicionar="true"
          :disabled="!form.clienteParticipanteId?.value || apenasVisualizar"
          obrigatorio
          com-botao-adicionar
        >
          <template #botaoAdicionar>
            <botao-padrao
              :botao-adicionar-input="true"
              :disabled="!form.clienteParticipanteId?.value || apenasVisualizar"
              @click="abreModalAdicionaClienteContato"
            >
              <span style="font-size: 23px"> + </span>
            </botao-padrao>
          </template>
        </input-select>
      </div>
      <input-cliente
        v-model="form.solicitanteParticipanteId"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.solicitante')
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-4"
        obrigatorio
        :disabled="apenasVisualizar || !!this.$route.query?.enviomaterial || !!form.envioMaterial"
      />
      <input-date
        v-model="form.dataRecebimento"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.data_recebimento'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :disabled="apenasVisualizar"
      />
      <input-date
        v-model="form.dataPrevisaoEntrega"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.data_previsao'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :class="apenasVisualizar ? 'col-12 col-md-2' : 'col-12 col-md-3'"
        apenas-datas-futuras
        :disabled="apenasVisualizar"
      />
      <input-date
        v-if="apenasVisualizar"
        v-model="form.dataEntrega"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.entrega')
        "
        class="col-12 col-md-2"
        disabled
      />
      <input-select
        v-model="form.prioridadeId"
        :options="opcoes.prioridade"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.prioridade')
        "
        :placeholder="$t('geral.inputs.selecione')"
        :class="apenasVisualizar ? 'col-12 col-md-2' : 'col-12 col-md-3'"
        :disabled="apenasVisualizar"
        clearable
      />
      <input-text
        v-model="form.notaFiscal"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.nota_fiscal')
        "
        class="col-12 col-md-3"
        :disabled="apenasVisualizar"
      />
      <input-select
        v-model="form.embalagemId"
        :options="opcoes.embalagens"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.tipo_embalagem'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :loading="loading.embalagensLoading"
        :disabled="apenasVisualizar"
        clearable
      />
      <input-select
        v-model="form.tipoEnvioId"
        :options="opcoes.tipoEnvio"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.tipo_envio')
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :loading="loading.tipoEnvioLoading"
        obrigatorio
        :disabled="apenasVisualizar || !!this.$route.query?.enviomaterial || !!form.envioMaterial"
      />
      <input-select
        v-model="form.localExecucaoId"
        :options="opcoes.localExecucao"
        :label="
          $t(
            'modulos.ordem_servico.formulario.dados_ordem_servico.local_execucao'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        :loading="loading.localExecucaoLoading"
        obrigatorio
        :disabled="apenasVisualizar"
      />
      <input-select
        v-model="form.situacao"
        :options="opcoes.EnumSituacaoOrdemServico"
        :label="
          $t('modulos.ordem_servico.formulario.dados_ordem_servico.situação')
        "
        :placeholder="$t('geral.inputs.selecione')"
        class="col-12 col-md-3"
        disabled
      />
      <requisito-modal
        ref="modal-requisito"
        :requisitos-cliente="dadosRequisitosClienteFormatado"
      />
      <criacao-instrumento-modal ref="modal-criacao-instrumento" />
      <criacao-cliente-modal
        ref="modal-criacao-cliente"
        :cliente-id="clienteId"
        @atualiza-contato="atualizaContato"
      />
    </v-form>
    <modal-envio-material
      ref="modal-envio-material"
      @atribuirEnvioMaterial="(v) => $emit('atribuirEnvioMaterial', v)"
    />
  </div>
</template>

<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import { DropdownModelDoisParametros } from '@/common/models/geral/DropdownModelDoisParametros';
import { InputCliente } from '@components/inputs';
import LocalExecucaoService from '@/common/services/cadastros/LocalExecucaoService';
import PrioridadeService from '@/common/services/cadastros/PrioridadeService';
import EnvioMaterialService from '@/common/services/cadastros/EnvioMaterialService';
import TipoEnvioService from '@/common/services/cadastros/TipoEnvioService';
import EmbalagensService from '@/common/services/cadastros/EmbalagensService';
import EnumeradorService from '@/common/services/cadastros/EnumeradorService';
import ParticipanteService from '@/common/services/cadastros/ParticipanteService';
import CriacaoInstrumentoModal from './modais/CriacaoInstrumentoModal.vue';
import CriacaoClienteModal from './modais/CriacaoClienteModal.vue';
import { RequisitoModal } from '@components/requisitos';
import ModalEnvioMaterial from './modais/EnvioMaterial.vue';
import helpers from '@/common/utils/helpers';
import _ from 'lodash';

export default {
  name: 'DadosOrdemServico',
  components: {
    InputCliente,
    RequisitoModal,
    CriacaoInstrumentoModal,
    CriacaoClienteModal,
    ModalEnvioMaterial,
  },
  emits: ['validarFormulario'],
  props: {
    form: { type: Object, default: null },
    apenasVisualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      dadosRequisitosClienteFormatado: null,
      clienteId: null,
      loading: {
        localExecucaoLoading: true,
        tipoEnvioLoading: true,
        embalagensLoading: true,
        prioridadeLoading: true,
        contatoLoading: false,
      },
      opcoes: {
        localExecucao: [],
        tipoEnvio: [],
        embalagens: [],
        EnumSituacaoOrdemServico: [],
        prioridade: [],
        contato: [],
      },
      validaValorAnteriorClienteParticipante: false,
    };
  },
  watch: {
    async 'form.clienteParticipanteId'(value, valorAnterior) {
      if (value?.value) {
        await this.buscarContato(value.value);
        this.buscarEnviosMateriaisPendentes();
        if (!this.form.id) {
          this.buscaRequisitos(value.value);
          if (!this.$route.query?.enviomaterial)
            this.form.solicitanteParticipanteId = value;
        }
      }
      if (this.validaValorAnteriorClienteParticipante) {
        this.validaValorAnteriorClienteParticipante = false;
        return;
      }
      if (
        value?.value &&
        valorAnterior?.value &&
        valorAnterior?.value !== value?.value &&
        this.form.ordensServicosInstrumentos.length
      ) {
        this.confirmarAlteracaoClienteParticipante()
          .then(() => {
            this.form.ordensServicosInstrumentos = [];
          })
          .catch(() => {
            this.form.clienteParticipanteId = valorAnterior;
            this.validaValorAnteriorClienteParticipante = true;
            return;
          });
      }
    },
  },
  async mounted() {
    const promisesOpcoes = [];
    promisesOpcoes.push(this.buscarLocalExecucao());
    promisesOpcoes.push(this.buscarTiposEnvio());
    promisesOpcoes.push(this.buscarEmbalagens());
    promisesOpcoes.push(this.buscarEnumSituacaoOrdemServico());
    promisesOpcoes.push(this.buscarPrioridade());
    await Promise.all(promisesOpcoes);
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    buscarEnviosMateriaisPendentes() {
      if (this.form.id || this.$route.query?.enviomaterial) return;
      EnvioMaterialService.listar({
        ParticipanteId: this.form.clienteParticipanteId?.value,
        flagEnvioMaterialPendenteCliente: true,
      }).then((res) => {
        if (res.data.items.length) {
          this.$refs['modal-envio-material'].abrirModal(res.data.items);
        }
      });
    },
    atualizaContato(id) {
      if (id) this.buscarContato(id);
    },
    abreModalAdicionaCliente() {
      this.clienteId = null;
      this.$refs['modal-criacao-cliente'].abrirModal();
    },
    abreModalAdicionaClienteContato() {
      this.$refs['modal-criacao-cliente'].abrirModal(
        this.form.clienteParticipanteId?.value
      );
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.dadosRequisitosClienteFormatado = null;
      ParticipanteService.buscaRequisitosParticipante(id, 'tipoRequisito=1')
        .then((res) => {
          let dados = res?.data;
          this.dadosRequisitosClienteFormatado = res?.data;

          this.dadosRequisitosClienteFormatado.map((item) => {
            item.descricaoTipoDocumentoGeral = '';
          });

          this.dadosRequisitosClienteFormatado.map((item) => {
            if (item.requisitos.length) {
              item.requisitos.map((itemRequisito) => {
                if (itemRequisito.classificacaoRequisito == 'TipoDocumento') {
                  item.descricaoTipoDocumentoGeral += itemRequisito.descricao
                    ? itemRequisito.descricao + '<br>'
                    : '';
                }
              });
            }
          });
          this.dadosRequisitosClienteFormatado.map((item) => {
            if (item.requisitos.length) {
              item.requisitos.map((itemRequisito) => {
                itemRequisito.descricao = itemRequisito.descricao.replaceAll(
                  '\n',
                  '<br>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'
                );
              });
            }
          });

          // let dadosRequisitosCliente;
          // dadosRequisitosCliente = dados.reduce(
          //   (obj, { descricaoTipoRequisito, descricaoRequisito }) => {
          //     if (!obj[descricaoTipoRequisito])
          //       obj[descricaoTipoRequisito] = [];
          //     obj[descricaoTipoRequisito].push(descricaoRequisito);
          //     return obj;
          //   },
          //   {}
          // );

          // this.dadosRequisitosClienteFormatado = Object.keys(
          //   dadosRequisitosCliente
          // ).map((descricaoTipoRequisito) => {
          //   return {
          //     descricaoTipoRequisito,
          //     descricaoRequisito:
          //       dadosRequisitosCliente[descricaoTipoRequisito],
          //   };
          // });

          if (dados.length) {
            this.$refs['modal-requisito'].abrirModal();
            this.$emit('dados-requisito', dados);
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarEmbalagens() {
      return EmbalagensService.listar().then((res) => {
        this.opcoes.embalagens = new DropdownModel(res.data);
        this.loading.embalagensLoading = false;
      });
    },
    buscarTiposEnvio() {
      return TipoEnvioService.listar().then((res) => {
        let itens = res.data.items.filter(i => i.flagVisivelEnvioMaterial == true);
        this.opcoes.tipoEnvio = new DropdownModel(itens);
        this.loading.tipoEnvioLoading = false;
      });
    },
    buscarLocalExecucao() {
      return LocalExecucaoService.listar().then((res) => {
        let dados = _.cloneDeep(res.data);

        if (!this.apenasVisualizar) {
          const locais_execucao_instalacaoCliente = dados.filter(
            (el) => !el.instalacaoCliente
          ).length;
          if (
            locais_execucao_instalacaoCliente &&
            !this.form.id &&
            !this.$route.query?.enviomaterial
          ) {
            dados = dados.filter((el) => !el.instalacaoCliente);
            if (dados.length == 1)
              this.form.localExecucaoId = new DropdownModelDoisParametros(
                dados[0],
                'nome',
                'instalacaoCliente'
              );
          }

          if (this.$route.query?.enviomaterial) {
            const localExecucaoCriarOSEnvio = dados.find(
              (el) => el.flagEnvioMaterial
            );
            if (localExecucaoCriarOSEnvio)
              this.form.localExecucaoId = new DropdownModelDoisParametros(
                localExecucaoCriarOSEnvio,
                'nome',
                'instalacaoCliente'
              );
          }
        }
        this.opcoes.localExecucao = new DropdownModelDoisParametros(
          dados,
          'nome',
          'instalacaoCliente'
        );
        this.loading.localExecucaoLoading = false;
      });
    },
    buscarEnumSituacaoOrdemServico() {
      return EnumeradorService.buscar('EnumSituacaoOrdemServico').then(
        (res) => {
          this.opcoes.EnumSituacaoOrdemServico = res;
        }
      );
    },
    buscarPrioridade() {
      return PrioridadeService.listar().then((res) => {
        this.opcoes.prioridade = new DropdownModel(res.data.items);
        this.loading.prioridadeLoading = false;
      });
    },
    async buscarContato(id) {
      return await ParticipanteService.buscaParticipantesContatosPorId(id).then(
        (res) => {
          this.opcoes.contato = new DropdownModel(res.data);
        }
      );
    },
  },
};
</script>

<template>
  <div>
    <div class="mb-8">
      <span class="titulo-pagina">
        {{ tituloFormulario }}
      </span>
      <div>
        <v-chip
          v-if="form.codigo"
          class="mr-1"
          small
        >
          Código: {{ form.codigo }}
        </v-chip>
        <v-chip
          v-if="codigoEnvioMaterial && !form.envioMaterial?.codigo"
          class="mr-1"
          small
        >
          Envio Material: {{ codigoEnvioMaterial }}
        </v-chip>
        <v-chip
          v-if="form.envioMaterial?.codigo"
          class="mr-1"
          small
        >
          Envio Material: {{ form.envioMaterial?.codigo }}
        </v-chip>
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.ordem_servico.formulario.abas.dados_ordem_servico') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.ordem_servico.formulario.abas.instrumentos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.ordem_servico.formulario.abas.anexos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.ordem_servico.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-ordem-servico
          ref="dados-ordem-servico"
          :form="form"
          :apenas-visualizar="apenasVisualizar"
          @validarFormulario="validarFormulario"
          @dados-requisito="dadosRequisitos"
          @atribuirEnvioMaterial="atribuirEnvioMaterial"
        />
      </v-tab-item>
      <v-tab-item>
        <instrumentos
          :form="form"
          :apenas-visualizar="apenasVisualizar"
          @validarInstrumento="validarInstrumento"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos
          :form="form"
          :apenas-visualizar="apenasVisualizar"
        />
      </v-tab-item>
      <v-tab-item>
        <requisitos
          :form="form"
          :requisitos-cliente="dadosRequisitosClienteFormatado"
        />
      </v-tab-item>
    </v-tabs-items>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        v-if="apenasVisualizar"
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao @click="confirmacaoSalvar">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>

<script>
import helpers from '@/common/utils/helpers';
import { DropdownModel } from '@/common/models/geral/DropdownModel';

import Anexos from './components/Anexos.vue';
import { OrdemServicoModel } from '@/common/models/cadastros/OrdemServicoModel.js';
import DadosOrdemServico from './components/DadosOrdemServico.vue';
import Instrumentos from './components/Instrumentos';
import { Requisitos } from '@/components/requisitos';
import {
  EnvioMaterialService,
  ParticipanteService,
  OrdemServicoService,
} from '@/common/services/cadastros';

export default {
  name: 'OrdemServicoFormulario',
  components: {
    DadosOrdemServico,
    Instrumentos,
    Requisitos,
    Anexos,
  },
  props: {
    id: { type: String, default: null },
  },
  data() {
    return {
      indiceAba: 0,
      codigoEnvioMaterial: null,
      apenasVisualizar: false,
      dadosRequisitosClienteFormatado: null,
      valido: false,
      instrumentoCadastrado: false,
      form: new OrdemServicoModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
    };
  },
  computed: {
    desabilitarSalvar() {
      return !this.valido || !this.instrumentoCadastrado;
    },
    tituloFormulario() {
      return this.id
        ? this.apenasVisualizar
          ? this.$t('modulos.ordem_servico.titulos.visualizar')
          : this.$t('modulos.ordem_servico.titulos.editar')
        : this.$t('modulos.ordem_servico.titulos.novo');
    },
  },
  mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);

    if (this.$route.query?.enviomaterial)
      this.atribuirEnvioMaterial(this.$route.query?.enviomaterial);

    if (this.$route.name.includes('visualizar')) this.apenasVisualizar = true;
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'OrdemServico', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'OrdemServico', 'Inserir');
  },
  methods: {
    atribuirEnvioMaterial(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnvioMaterialService.buscar(id)
        .then((res) => {
          if (this.$route.query?.enviomaterial){
            this.form.clienteParticipanteId = new DropdownModel(res.data.contratante);
            this.codigoEnvioMaterial = res.data.codigo;
            this.form.anexos = res.data.anexos.map((anexo) => {
              return {
                ...anexo,
              };
            });
          }

          this.form.solicitanteParticipanteId = new DropdownModel(
            res.data.solicitante
          );
          this.form.tipoEnvioId = res.data.tipoEnvio?.id;
          this.form.clienteParticipanteContatoId =
            res.data.contatoContratante?.id;

          this.form.notaFiscal = res.data.notaFiscal;

          this.form.ordensServicosInstrumentos = res.data.instrumentos
            ?.filter((el) => el.participante.id === res.data.solicitante.id)
            .map((instrumento) => {
              return { ...instrumento, instrumentoId: instrumento.id };
            });
          this.form.envioMaterialId = id;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    dadosRequisitos(dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    buscaRequisitos(id) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscaRequisitosParticipante(id, 'tipoRequisito=1')
        .then((res) => {
          if (res?.data) this.dadosRequisitos(res?.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscar() {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.buscar(this.id)
        .then((res) => {
          this.form = new OrdemServicoModel(res.data);
          this.buscaRequisitos(res.data.clienteParticipante?.id);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.ordem_servico.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar() {
      if (!this.validarOrdemServico()) return;
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    validarOrdemServico() {
      if (this.$refs['dados-ordem-servico']) {
        this.valido = this.$refs['dados-ordem-servico'].$refs.form.validate();
        this.indiceAba = 0;
        if (!this.valido) return false;
      }
      if (!this.form.ordensServicosInstrumentos.length) {
        this.toastAlerta(
          this.$t('modulos.ordem_servico.validacoes.cadastrar_instrumento')
        );
        this.indiceAba = 1;
        return false;
      }
      return true;
    },
    salvar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrdemServicoService.salvar(this.form.request)
        .then(async (res) => {
          const codigoOrdemServico = res.data.codigo;
          this.toastSucesso(
            `Ordem de serviço ${
              this.form.codigo ?? codigoOrdemServico
            } salva com sucesso!`
          );
          if (!this.form.id) {
            this.$router.push({
              name: 'ordem-servico-email',
              params: { id: res.data.id },
            });
            return;
          }

          this.$router.push({
            name: this.$route.query?.retorno || 'ordem-servico',
          });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
          if (
            err.response.data.errors[0] == 'instrumentoJaEstaEmUmaOrdemServico'
          ) {
            this.toastAlerta(
              this.$t(
                `modulos.ordem_servico.erros.instrumento_ja_esta_em_uma_ordem_servico`
              )
            );
            this.indiceAba = 1;
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'ordem-servico',
        });
      });
    },
    validarFormulario(validar) {
      this.valido = validar;
    },
    validarInstrumento(validar) {
      this.instrumentoCadastrado = validar;
    },
    voltar: function () {
      this.$router.push({ name: 'ordem-servico' });
    },
    // buscarParticipanteSelecionado() {
    //   let participanteLogadoId =
    //     this.$store.getters['Autenticacao/participanteSelecionado'];
    //   ParticipanteService.buscar(participanteLogadoId).then((res) => {
    //     this.form.solicitanteParticipanteId = new DropdownModel(res.data);
    //   });
    // },
  },
};
</script>

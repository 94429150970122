<template lang="">
  <modal-padrao
    ref="modal-envio-material"
    max-width="60%"
    :titulo="$t(
      'modulos.ordem_servico.titulos.envios_materiais_pendentes'
    )"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.voltar')"
    @ok="salvar"
  >
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      filtro-geral
      class="mt-2"
      :colunas="tabela.colunas"
      sem-paginacao
      filtro-global-focus
      :filters="tabela.filters"
      selecionar-apenas-um
      ajustar-altura-linha
      :mostrar-acoes="false"
      :global-filters="[
        'codigo',
        'tipoEnvio.nome',
        'situacao',
        'dataAgendamento'
      ]"
      @fechar-modal-click-linha="() => salvar()"
    />
  </modal-padrao>
</template>
<script>
import helpers from '@common/utils/helpers';
import { FilterMatchMode } from 'primevue/api';

export default {
  props: {
    contratanteId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
        tabela: {
        selecionados: [],
        dados: [],
        colunas: [
            {
            value: 'codigo',
            text: this.$t('modulos.envio_material.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'dataSolicitacao',
            text: this.$t('modulos.envio_material.tabela.data_solicitacao'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'tipoEnvioMaterial',
            text: this.$t('modulos.envio_material.tabela.logistica'),
            sortable: true,
            formatter: v => helpers.TipoEnvioMaterial.find(el => el.value === v)?.text
          },
          {
            value: 'tipoEnvio.nome',
            text: this.$t('modulos.envio_material.tabela.tipo_envio'),
            sortable: true,
          },
          {
            value: 'cliente.nome',
            text: this.$t('modulos.envio_material.tabela.cliente'),
            sortable: true,
          },
          {
            value: 'dataAgendamento',
            text: this.$t('modulos.envio_material.tabela.data_agendamento'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'situacao',
            text: this.$t('modulos.envio_material.tabela.situacao'),
            sortable: true,
            formatter: v => helpers.SituacaoEnvioMaterialEnum.find(el => el.value === v)?.text
          },
        ],
        filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }
      }
    };
  },
  methods: {
    abrirModal: function (enviosPendentes) {
      this.tabela.dados = enviosPendentes;
      this.$refs['modal-envio-material'].abrirModal();
    },
    salvar() {
      this.$emit('atribuirEnvioMaterial', Array.isArray(this.tabela.selecionados) ? this.tabela.selecionados[0].id : this.tabela.selecionados.id);
      this.$refs['modal-envio-material'].fecharModal();
    },
  },
};
</script>
